import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
  Button
} from 'reactstrap';
import { connect } from 'react-redux'

import logo from './logo.png'
import logoOn from './logo_on.png'
import { signOut } from './store/actions/actions'

import ApiKeys from './components/ApiKeys/ApiKeys'
import ApiRequest from './components/ApiRequests/ApiRequest'
import ApiRequests from './components/ApiRequests/ApiRequests'
import SfUsers from './components/SfUsers/SfUsers'
import SfUser from './components/SfUsers/SfUser'


class Routes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isOpen: false}
  }
  toggle = () => {
    this.setState({isOpen: !this.state.isOpen})
  }
  render() {
    let logoImg = null
    if (this.props.user.inQueue) {
      logoImg = <img src={logoOn} alt="Rail Gun Active" />
    } else {
      logoImg = <img src={logo} alt="Rail Gun" />
    }
    return(
      <Router>
        <Navbar color="light" light expand="md" className="mb-3">
          <Link to="/" className="navbar-brand">
            {logoImg}
          </Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <Link to="/api_keys" className="nav-link">Keys</Link>
              </NavItem>
              <NavItem>
                <Link to="/api_requests" className="nav-link">Requests</Link>
              </NavItem>
              <NavItem>
                <Link to="/sf_users" className="nav-link">Salesforce Users</Link>
              </NavItem>
            </Nav>
            <NavbarText>
              <Button onClick={this.props.signOut}>Sign Out</Button>
            </NavbarText>
          </Collapse>
        </Navbar>
        <Switch>
          <Route path="/api_keys"><ApiKeys /></Route>
          <Route path="/api_requests/:id" render={({match}) => (
            <ApiRequest id={match.params.id} />
          )}/>
          <Route path="/api_requests"><ApiRequests /></Route>
          <Route path="/sf_users/:id" render={({match}) => (
            <SfUser id={match.params.id} />
          )}/>
          <Route path="/sf_users"><SfUsers /></Route>
        </Switch>
        <footer>
          &copy; TruRooms Inc.
        </footer>
      </Router>
    )
  }
}

const mapStateToProps = state => ({ user: state.user })

export default connect(
  mapStateToProps,
  { signOut }
)(Routes);