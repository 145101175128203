export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_IN_QUEUE = 'SET_IN_QUEUE';


/**
 * Sign in current User
 */
export function signIn(authObj) {
    return { type: SIGN_IN, payload: authObj };
}

export function signOut(authObj) {
  return { type: SIGN_OUT, payload: authObj}
}

export function setInQueue(bool) {
  return { type: SET_IN_QUEUE, payload: bool}
}