import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import JSONTree from 'react-json-tree'
import Api from '../../services/Api'

export default class ApiRequest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {request: { attributes: {} }}
  }
  componentDidMount() {
    this.fetchRequest()
  }
  fetchRequest = () => {
    let api = new Api()
    api.getApiRequest(this.props.id, {exec: null}, (status, response) => {
      if (status === 200) {
        this.setState({request: response.data})
      }
    })
  }
  render() {
    return(
      <Container>
        <Row>
          <Col>
            {this.state.request.attributes.created_at}
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Status</h3>
            {this.state.request.attributes.status}
          </Col>
          <Col>
            <h3>Response Status</h3>
            {this.state.request.attributes.response_status}
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Lux Data</h3>
            <JSONTree data={this.state.request.attributes.lux_data} />
          </Col>
          <Col>
            <h3>Payload</h3>
            <JSONTree data={this.state.request.attributes.payload} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Response</h3>
            <JSONTree data={this.state.request.attributes.response} />
          </Col>
        </Row>
      </Container>
    )
  }
}