import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Card } from 'reactstrap'
import logo from '../logo.png'

import { signIn } from '../store/actions/actions'

class SignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {authWindow: null, authCreds: {token: ''}}
  }
  componentDidMount() {
    window.addEventListener('message', event => {
      if (event.origin === `${process.env.REACT_APP_BASE_API_PATH}`) {
        let data = JSON.parse(event.data)
        this.setState({authCreds: {token: data.token}})
        this.props.signIn({token: data.token, expiry: data.expiry, client: data.client, uid: data.uid, id: data.id, inQueue: data.inQueue})
      }
    }); 
  }
  openAuthWindow = (e) => {
    e.preventDefault()
    let win = window.open(`${process.env.REACT_APP_BASE_API_PATH}/auth/azureactivedirectory`)
    this.setState({authWindow: win})
  }
  render() {
    return(
      <Row>
        <Col md={{size: 2, offset: 5}}>
          <img src={logo} alt="From the Deep" className="img-fluid mb-3" />
          <Button onClick={this.openAuthWindow} block>Sign In</Button>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => ({ user: state.user })

export default connect(
  mapStateToProps,
  { signIn }
)(SignIn);