import { SIGN_IN, SIGN_OUT, SET_IN_QUEUE } from '../actions/actions';

const initialState = {
    token: null,
    expiry: null,
    client: null,
    uid: null,
    id: null
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN:
            return signIn(state, action.payload)
        case SIGN_OUT:
          return signOut(state, action.payload)
        case SET_IN_QUEUE:
          return setInQueue(state, action.payload)
        default:
            return state;
    }
}

function signIn(state, payload) {
  console.log(payload)
  return Object.assign({}, state, {
    token: payload.token,
    expiry: payload.expiry,
    client: payload.client,
    uid: payload.uid,
    id: payload.id,
    inQueue: payload.inQueue
  }) 
}

function signOut(state, payload) {
  return Object.assign({}, state, {
    token: null,
    expiry: null,
    client: null,
    uid: null
  }) 
}

function setInQueue(state, payload) {
  return Object.assign({}, state, {
    inQueue: payload
  }) 
}

export default usersReducer;