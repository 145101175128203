import React from 'react';
import { connect } from 'react-redux'
import './App.css';

import SignIn from './components/SignIn'
import Main from './Main'

class App extends React.Component {
  render() {
    if (this.props.user.token === null) {
      return <SignIn />
    } else {
      return <Main user={this.props.user} />
    }
  }
}

const mapStateToProps = state => ({ user: state.user })

export default connect(
  mapStateToProps,
  { }
)(App);
