import React from 'react'
import { Container, Row, Col, Table, Label, Input, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import Api from '../../services/Api'

class UserRow extends React.Component {
  render() {
    return(
      <tr>
        <td>{this.props.name}</td>
        <td>{this.props.email}</td>
        <td>{this.props.sf_id}</td>
        <td><Link to={`/sf_users/${this.props.id}`}>Details</Link></td>
      </tr>
    )
  }
}
export default class SfUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {requests: [], name: '', email: '', sf_id: ''}
  }
  componentDidMount() {
    this.fetchRequests()
  }
  fetchRequests = () => {
    let api = new Api()
    api.getSfUsers({exec: null}, (status, response) => {
      if (status === 200) {
        this.setState({requests: response.data})
      }
    })
  }
  handleInput = (e) => {
      let state = this.state
      state[e.target.name] = e.target.value
      this.setState(state)
  }
  createUser = (e) => {
      e.preventDefault();
      let api = new Api()
      api.createSfUser({sf_user: {name: this.state.name, email: this.state.email, sf_id: this.state.sf_id}}, (status, response) => {
          if (status === 201) {

          } else {

          }
      })
  } 
  render() {
    let rows = this.state.requests.map((req) => {
      return <UserRow key={req.id} id={req.id} createdAt={req.attributes.created_at} name={req.attributes.name} email={req.attributes.email} sf_id={req.attributes.sf_id} />
    })
    return(
      <Container>
        <Row className="mb-3">
            <Col>
                <Label>Name</Label>
                <Input type="text" name="name" value={this.state.name} onChange={this.handleInput} />
            </Col>
            <Col>
                <Label>Email</Label>
                <Input type="text" name="email" value={this.state.email} onChange={this.handleInput} />
            </Col>
            <Col>
                <Label>Salesforce ID</Label>
                <Input type="text" name="sf_id" value={this.state.sf_id} onChange={this.handleInput} />
            </Col>
            <Col>
                <Label>&nbsp;</Label><br />
                <Button color="success" onClick={this.createUser}>Save</Button>
            </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Salesforce ID</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    )
  }
}