import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Routes from './Routes'

class Main extends React.Component {
  render() {
    return(
      <Routes />
    )
  }
}

export default Main