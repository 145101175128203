import React from 'react'
import { Container, Row, Col, Table, Input, Button, Form, FormGroup } from 'reactstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Truncate from 'react-truncate';

import Api from '../../services/Api'

class ApiKeyToggle extends React.Component {
  toggleActivation = (bool) => {
    let api = new Api()
    api.updateApiKey(this.props.id, {active: bool}, (status, response) => {
      if (status === 200) {

      }
    })
  }
  render() {
    if (this.props.active === true) {
      return(
        <Button type="button" color="danger" onClick={() => {this.toggleActivation(false)}}>Deactivate</Button>
      )
    } else {
      return(
        <Button type="button" color="success" onClick={() => {this.toggleActivation(true)}}>Activate</Button>
      )
    }
  }
}

class NewApiKey extends React.Component {
  constructor(props) {
    super(props)
    this.state = {editing: false, saving: false, description: ''}
  }
  handleClick = () => {
    if (this.state.editing === false) {
      this.setState({editing: true})
    } else if (this.state.saving === false) {
      this.setState({saving: true}, () => {
        this.createApiKey()
      })
    }
  }
  buttonText = () => {
    if (this.state.editing === false) {
      return 'New Api Key'
    } else {
      return 'Save API Key'
    }
  }
  handleChange = (e) => {
    this.setState({description: e.target.value})
  }
  createApiKey = () => {
    let api = new Api()
    api.createApiKey({description: this.state.description}, (status, response) => {
      if (status === 201) {
        this.setState({editing: false, saving: false})
      }
    })
  }
  cancel = (e) => {
    e.preventDefault()
    this.setState({editing: false})
  }
  render() {
    return(
      <Row className="text-right mb-3">
        <Col>
          <Form onSubmit={this.createApiKey}>
            <Row style={{display: this.state.editing ? 'block':'none'}}>
              <Col>
                <Input type="text" placeholder="Description" value={this.state.description} onChange={this.handleChange} />
                <Button color="link" onClick={this.cancel}>Cancel</Button>
              </Col>
            </Row>
            <Button color="success" onClick={this.handleClick}>{this.buttonText()}</Button>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default class ApiKeys extends React.Component {
  constructor(props) {
    super(props)
    this.state = { apiKeys: [] }
  }
  componentDidMount() {
    this.fetchApiKeys()
  }
  fetchApiKeys = () => {
    let api = new Api
    api.getApiKeys({exec: null}, (status, response) => {
      if (status === 200) {
        this.setState({apiKeys: response.data})
      }
    })
  }
  handleUpdate = (apiKey) => {
    let apiKeys = this.state.apiKeys
  }
  render() {
    let rows = this.state.apiKeys.map((key) => {
      return (
        <tr key={key.id}>
          <td>{key.attributes.description}</td>
          <td><Truncate width={200}>{key.attributes.key}</Truncate></td>
          <td>
            <CopyToClipboard onCopy={this.onCopy} text={key.attributes.key}>
              <Button size="sm">Copy</Button>
            </CopyToClipboard></td>
          <td>{key.attributes.usage_count}</td>
          <td>{key.attributes.last_used}</td>
          <td><ApiKeyToggle active={key.attributes.active} id={key.id} /></td>
        </tr>
      )
    })
    return(
      <Container>
        <NewApiKey />
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  <td>Description</td>
                  <td>Key</td>
                  <td>Usage</td>
                  <td>Last Used</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    )
  }
}