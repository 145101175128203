import React from 'react'
import { Container, Row, Col, Table, Label, Input } from 'reactstrap'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";
import Api from '../../services/Api'

class RequestRow extends React.Component {
  render() {
    return(
      <tr>
        <td>{this.props.createdAt}</td>
        <td>{this.props.status}</td>
        <td>{this.props.event}</td>
        <td>{this.props.responseStatus}</td>
        <td><Link to={`/api_requests/${this.props.id}`}>Details</Link></td>
      </tr>
    )
  }
}
export default class ApiRequests extends React.Component {
  constructor(props) {
    super(props)
    this.state = {requests: [], page: 1, total_pages: 1}
  }
  componentDidMount() {
    this.fetchRequests()
  }
  fetchRequests = () => {
    let api = new Api()
    api.getApiRequests(this.state.page, {exec: null}, (status, response) => {
      if (status === 200) {
        this.setState({requests: response.data, page: response.meta.page, total_pages: response.meta.total})
      }
    })
  }
  pageChange = (page) => {
    this.setState({page: page}, () => {
        this.fetchRequests();
    })
  }
  render() {
    let rows = this.state.requests.map((req) => {
      return <RequestRow key={req.id} id={req.id} createdAt={req.attributes.created_at} status={req.attributes.status} event={req.attributes.event} responseStatus={req.attributes.response_status} />
    })
    return(
      <Container>
        <Row>
          <Col>
            <Label>Event</Label>
            <Input type="text"></Input>
          </Col>
          <Col>
            <Label>Status</Label>
            <Input type="text"></Input>
          </Col>
          <Col>
            <Label>Response</Label>
            <Input type="text"></Input>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Event</th>
                  <th>Response</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
            <Col>
            <Pagination
                    activePage={this.state.page}
                    itemsCountPerPage={30}
                    totalItemsCount={this.state.total_entries}
                    pageRangeDisplayed={5}
                    onChange={this.pageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
            </Col>
        </Row>
      </Container>
    )
  }
}