import React from 'react'
import { Container, Row, Col, Label, Input, Button } from 'reactstrap'
import Api from '../../services/Api'

export default class ApiRequest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {name: ''}
  }
  componentDidMount() {
    this.fetchRequest()
  }
  fetchRequest = () => {
    let api = new Api()
    api.getSfUser(this.props.id, {exec: null}, (status, response) => {
      if (status === 200) {
          let state = this.state
          state.name = response.data.attributes.name
          state.email = response.data.attributes.email
          state.sf_id = response.data.attributes.sf_id
          this.setState(state)
      }
    })
  }
  handleChange = (e) => {
      let state = this.state
      state[e.target.name] = e.target.value
      this.setState(state)
  }
  handleSave = (e) => {
      e.preventDefault()
      let api = new Api()
      api.updateSfUser(this.props.id, {sf_user: {name: this.state.name, email: this.state.email, sf_id: this.state.sf_id}}, (status, response) => {
          if (status === 200) {
              alert('Changes Saved')
          }
      })
  }
  render() {
    return(
      <Container>
        <Row className="mb-3">
          <Col>
            <Label>Name</Label>
            <Input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
          </Col>
          <Col>
            <Label>Email</Label>
            <Input type="text" name="email" value={this.state.email} onChange={this.handleChange} />
          </Col>
          <Col>
            <Label>Salesforce ID</Label>
            <Input type="text" name="sf_id" value={this.state.sf_id} onChange={this.handleChange} />
          </Col>
        </Row>
        <Row>
            <Col>
                <Button color="success" onClick={this.handleSave}>Save Changes</Button>
            </Col>
        </Row>
      </Container>
    )
  }
}